import { Box, Card, Table, Typography, Button, Modal, Sheet } from '@mui/joy';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import useWidth from '../../hooks/useWidth';
import { StatusForAll } from '../../utils/status';

import { createNotification } from '../../services/merchant';
import Timer from '../../components/Timer';

import { Cards } from '../../utils/types';
import { socket } from '../../socket';
import { useTranslation } from 'react-i18next';

const OrderExecution = ({
  card,
  amount,
  confirmTrans,
  setConfirmTrans,
  status,
  timerCloseCard,
  setTimerCloseCard,
  sig,
  api_key,
}: {
  card: Cards | null;
  amount: string;
  confirmTrans: boolean;
  setConfirmTrans: Dispatch<SetStateAction<boolean>>;
  status: string;
  timerCloseCard: string | null;
  setTimerCloseCard: Dispatch<SetStateAction<number | null>>;
  sig: string | null;
  api_key: string | null;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);

  const time = new Date((timer && timer) || '');
  const timeForClose = new Date((timerCloseCard && timerCloseCard) || '');

  const urlParams = new URLSearchParams(window.location.search);
  const session = urlParams.get('session');

  socket.io.opts.extraHeaders = {
    key: session as string,
  };

  socket.connect();
//TODO: remove socket "notificationStatus"
  useEffect(() => {
    socket.on('notificationStatus', (data: boolean) => {
      setAcceptTrans(true);
      setAccept(data);
    });

    return () => {
      socket.off('notificationStatus');
    };
  }, []);

  const { isMobile } = useWidth();
  const [acceptTrans, setAcceptTrans] = useState(false);
  const [accept, setAccept] = useState(true);

  const handleConfirmTrans = async () => {
    if (card) {
      const notification = await createNotification(sig, api_key);
      setConfirmTrans(true);
      setTimer(notification?.timer?.timer);
      setTimerCloseCard(null);
    }
    return;
  };
  return (
    <Box sx={{ p: '40px 0', width: '100%' }}>
      {!confirmTrans && status === StatusForAll.success && card && (
        <>
          <Typography level="h3" sx={{ textAlign: 'center', width: '70%', m: '0 auto', mb: 2 }}>
            {t('transaction_header')}
          </Typography>
          <Typography
            level="body-md"
            sx={{
              textAlign: 'center',
              width: '70%',
              m: '0 auto',
              mb: 2,
              color: 'var(--error-color)',
            }}
          >
            {t('transaction_info')}
          </Typography>
          {timerCloseCard && <Timer expiryTimestamp={timeForClose} />}
          <Card
            sx={{
              m: '0 auto',
              bgcolor: 'var(--accent-light)',
              width: (isMobile && '100%') || '60%',
            }}
          >
            <Typography level="h4" sx={{ p: 2 }}>
              {t('transaction_pay_info')}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td> {t('transaction_bank')}</td>
                  <td>{card?.bank.bank.bank_name}</td>
                </tr>
                <tr>
                  <td> {t('transaction_card')}</td>
                  <td>{card?.card_number}</td>
                </tr>
                <tr>
                  <td> {t('transaction_price')}</td>
                  <td>{`${amount} ${localStorage.getItem('base_currency')}`}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Button
            onClick={() => setOpen(!open)}
            sx={{
              display: 'block',

              m: '0 auto',
              mt: 3,
              p: '12px 30px',
            }}
          >
            {t('transaction_btn')}
          </Button>
          <Modal
            open={open}
            onClose={() => {
              setOpen(!open);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: (isMobile && '80%') || '40%',
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              <Typography level="body-lg" sx={{ textAlign: 'center', mb: 1 }}>
                {t('transaction_modal_header')}
              </Typography>
              <Typography level="body-xs" sx={{ textAlign: 'center', mb: 2 }}>
                {t('transaction_modal_amount', { amount, currency: localStorage.getItem('base_currency') })}
              </Typography>
              <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                <Button type="button" color="primary" onClick={handleConfirmTrans}>
                  {t('transaction_modal_btnYes')}
                </Button>
                <Button type="button" color="neutral" onClick={() => setOpen(!open)}>
                  {t('transaction_modal_btnNo')}
                </Button>
              </Box>
            </Sheet>
          </Modal>
        </>
      )}
      {!confirmTrans && status === StatusForAll.success && !card && (
        <>
          <Typography
            level="h3"
            sx={{ textAlign: 'center', width: '70%', m: '0 auto', mb: 4 }}
            color="danger"
          >
            {t('transaction_nocard_header')}
          </Typography>
          <Button sx={{ display: 'block', m: '0 auto' }}>{t('transaction_tech_support')}</Button>
        </>
      )}
      {confirmTrans && (
        <Box>
          {!acceptTrans && (
            <>
              <Typography level="h4" sx={{ textAlign: 'center', width: '70%', m: '0 auto', mb: 4 }}>
                {t('transaction_timer_header')}
              </Typography>
              {timer && <Timer expiryTimestamp={time} />}
              <Loader />
            </>
          )}
          {acceptTrans && (
            <>
              {accept && (
                <>
                  <Typography
                    level="h4"
                    sx={{ textAlign: 'center', width: '70%', m: '0 auto', mb: 4 }}
                  >
                    {t('transaction_accept')}
                  </Typography>
                  <Button sx={{ display: 'block', m: '0 auto' }}>
                    {t('transaction_tech_support')}
                  </Button>
                </>
              )}
              {!accept && (
                <>
                  <Typography
                    level="h4"
                    sx={{ textAlign: 'center', width: '70%', m: '0 auto', mb: 4 }}
                  >
                    {t('transaction_noaccept')}
                  </Typography>
                  <Button sx={{ display: 'block', m: '0 auto' }}>
                    {t('transaction_tech_support')}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OrderExecution;
