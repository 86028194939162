import React, { useEffect, useState } from 'react';
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import { closeSidebar } from '../../utils/closeSidebar';
import { Link, NavLink, useLocation } from 'react-router-dom';
import css from './Sidebar.module.css';
import {
  getActivePayouts,
  getActiveTransactions,
  logOut,
  refreshNotificationStatus,
  resetFrozenBalance,
  updateFrozenBalance,
  updateUserPayoutStatus,
  updateUserTransactionStatus,
} from '../../redux/auth/operations';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { Button, IconButton, Switch, switchClasses, Theme } from '@mui/joy';
import { AppDispatch } from '../../redux/store';
import useWidth from '../../hooks/useWidth';
import { useTranslation } from 'react-i18next';
import { socket } from '../../socket';
import { Payout, PayoutsData, Transactions, TransactionsData } from '../../utils/types';
import ModalTopUp from '../Wallet/ModalTopUp';
import ModalMyWallet from '../Wallet/ModalMyWallet';
import Logo from '../Logo/Logo';
import { boxStyle, triangleStyle, boxStyleM } from './styles.js';
import IconComponent from '../IconComponent/IconComponent';
import { getConstants } from '../../services/operators';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const Sidebar = ({ onSidebarClick }: { onSidebarClick: () => void }) => {
  const { t } = useTranslation();
  const { isLoggedIn, user, activeTransactions, activePayouts } = useSelector(getGlobalUser);
  const dispatch = useDispatch<AppDispatch>();

  const [accessibility, setAccessibility] = useState(user.isActiveTransaction);
  const [payoutAccessibility, setPayoutAccessibility] = useState(user.is_active_payout);
  //const [payoutPermitted, setPayoutPermitted] = useState(user.is_payout_permitted);
  const { isMobile } = useWidth();
  const [openModal, setOpenModal] = useState(false);
  const [amount, setAmount] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isValidTopUp, setIsValidTopUp] = useState(false);
  const [isInvalidTopUp, setIsInvalidTopUp] = useState(false);
  const [myWalletModal, setMyWalletModal] = useState(false);
  const [showPayin, setShowPayin] = useState(false);
  const [showPayinQAC, setShowPayinQAC] = useState(false);
  const [showPayOut, setShowPayOut] = useState(false);
  const [hideMenu, setHideMenu] = useState(true);
  const [show, setShow] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showPayIn, setShowPayIn] = useState(false);
  const [showPayInQAC, setShowPayInQAC] = useState(false);
  const [showPayOutTitle, setShowPayOutTitle] = useState(false);

  const [minAmounTopup, setMinAmountTopup] = useState('');

  const { pathname } = useLocation();

  const handleButtonClick = () => {
    setShowPayin(!showPayin);
  };

  const openPayinQAC = () => {
    setShowPayinQAC(!showPayinQAC);
  };
  const openPayOut = () => {
    setShowPayOut(!showPayOut);
  };

  const toggleMenu = () => {
    setHideMenu(!hideMenu);
    onSidebarClick();
  };

  useEffect(() => {
    setAccessibility(user.isActiveTransaction);
    setPayoutAccessibility(user.is_active_payout);
  }, [user]);

  const activeClassName = ({ isActive }: { isActive: boolean }) =>
    isActive ? `${css.active}` : `${css.navLink}`;

  const handleSignOut = () => {
    closeSidebar();
    logOut();
  };

  const handleChangeCheckbox = async () => {
    if (user?.id) {
        dispatch(updateUserTransactionStatus({
        id: user.id,
        isActiveTransaction: !accessibility
      }));
      // if (updatedUser?.arg.isActiveTransaction) {
      //   toast.success(t("toastActivatedTrading"))
      // }
      setAccessibility(!accessibility);
    }
  };

  const handlePayoutChangeCheckbox = async () => {
    if (user?.id) {
      dispatch(updateUserPayoutStatus({
        id: user.id,
        is_active_payout: !payoutAccessibility
      }));
      setPayoutAccessibility(!payoutAccessibility);
    } else {
      toast.error(i18n.t('toast_payout_on_error'), {
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      socket.on('findAll', (data: TransactionsData) => {
        if (data?.transactions.length > 0) {
          const filteredNotifications = data?.transactions?.filter(
            (notification: Transactions) => notification.status === 'PROCESSING'
          );
          dispatch(getActiveTransactions(filteredNotifications));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const playNotification = async () => {
      const { is_notification_enabled } = await refreshNotificationStatus(user.id);
      if (is_notification_enabled === true) {
        const audio = new Audio('/audio/notification.mp3');
        audio.play().catch((error) => {
          console.error('Failed to play audio:', error);
        });
      }
    };

    socket.on('play', playNotification);

    return () => {
      socket.off('play', playNotification);
    };
  }, [isLoggedIn, user.id]);

  useEffect(() => {
    if (isLoggedIn) {
      socket.on('findAllPayouts', (data: PayoutsData) => {
        if (data?.payouts.length > 0) {
          const filteredNotifications = data?.payouts?.filter(
            (payout: Payout) => payout.status === 'PROCESSING'
          );
          dispatch(getActivePayouts(filteredNotifications));
        }
      });
    }
  }, []);

  const toggleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const handleChange = (value: string) => {
    setAmount(value);
    const isValidValue = value.length >= 2 && parseInt(value) >= Number(minAmounTopup) && parseInt(value) <= user.deposit;
    const isInvalidValue =
      value.length < 2 ||
      (value.length === 2 && parseInt(value) < Number(minAmounTopup)) ||
      parseInt(value) > user.deposit;
    setIsValidTopUp(isValidValue);
    setIsInvalidTopUp(isInvalidValue);
    if (parseInt(value) >= Number(minAmounTopup) && parseInt(value) <= user.deposit) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const onSubmit = () => {
    user?.id && dispatch(updateFrozenBalance({ amount: Number(amount), id: user.id }));
    toggleOpenModal();
    setAmount('');
    setMyWalletModal(true);
  };

  const handleRemoveFrozenBalance = () => {
    user?.id && dispatch(resetFrozenBalance({ id: user.id }));
    toggleOpenModal();
    setAmount('');
  };

  useEffect(() => {
    const fetch = async () => {
      const resp = await getConstants();
      if (resp) {
        setMinAmountTopup(resp.MIN_TRADING_BALANCE_TOP_UP);
      }
    }
    fetch();
  }, [])

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 20,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          overflowY: 'scroll',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        color="neutral"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1.1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          transition: 'transform 0.4s',
          zIndex: 21,
          width: hideMenu ? '280px' : '140px',
          height: '100dvh',
          // height: '100%',
          top: 0,
          p: 2,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRight: '1px solid',
          borderColor: 'divider',
          overflowY: (hideMenu || isMobile) ? 'scroll' : '',
          '&::-webkit-scrollbar': {
            width: '2px', 
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: hideMenu ? 'space-between' : 'center' }}>
          {hideMenu && <Logo />}
          <IconButton onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16"
              fill="none"
              style={{ transform: !hideMenu ? 'rotate(180deg)' : 'rotate(0deg)' }}
            >
              <path
                fill="#868E96"
                d="M14.7 14.3a1 1 0 0 1-1.4 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 1 1 1.4 1.4L8.4 8l6.3 6.3ZM0 15a1 1 0 1 0 2 0V1a1 1 0 0 0-2 0v14Z"
              />
            </svg>
          </IconButton>
        </Box>
        <List
          size="sm"
          sx={{
            '--ListItem-radius': '6px',
            '--List-gap': '6px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{ width: '16px', height: '16px', background: '#495057', borderRadius: '50%' }}
            ></span>
            {hideMenu && (
              <Typography sx={{ ml: '4px', fontWeight: 500, fontSize: '16px', color: '#868E96' }}>
                {t('myWalletBalance')}:
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: hideMenu ? 'nowrap' : 'wrap',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              mt: hideMenu ? 0 : 1,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1' }}
            >
              {Number(user.deposit).toFixed(2)}{' '}
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1', ml: 1 }}
            >
              USDT
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              alignItems: 'center',
              mt: '20px',
            }}
          >
            <span
              style={{ width: '16px', height: '16px', background: '#947EFE', borderRadius: '50%' }}
            ></span>
            {hideMenu && (
              <Typography sx={{ ml: '4px', fontWeight: 500, fontSize: '16px', color: '#868E96' }}>
                {t('tradingBalance')}:
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: hideMenu ? 'nowrap' : 'wrap',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              mt: hideMenu ? 0 : 1,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1' }}
            >
              {Number(user.frozen_limit).toFixed(2)}{' '}
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1', ml: 1 }}
            >
              USDT
            </Typography>
          </Box>
          {hideMenu && (
            <Button
              variant='outlined'
              className="btnGradient"
              sx={{

                width: '100%',
                padding: (isMobile && '10px') || '10px 14px',
                fontSize: (isMobile && '14px') || '16px',
                color:'#F1F1F1',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
                mt: 2,
              }}
              onClick={toggleOpenModal}
            >
              {t('topUpTrading')}
            </Button>
          )}

          {hideMenu && (
            <ListItem
              sx={{
                margin: '16px 0',
              }}
            >
              <ListItemButton
                onClick={() => closeSidebar()}
                sx={{
                  borderRadius: '8px',
                  padding: (isMobile && '10px') || '10px 14px',
                  border: 'none',
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
              >
                <ListItemContent>
                  <Link
                    to="myWallet"
                    style={{
                      color: 'rgba(241, 241, 241, 1)',
                      fontWeight: 600,
                      fontSize: (isMobile && '14px') || '16px',
                      textAlign: 'center',
                    }}
                  >
                    {t('sidebarNavMyWallet')}
                  </Link>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              alignItems: 'center',
              mt: hideMenu ? '0' : '20px',
            }}
          >
            <span
              style={{ width: '16px', height: '16px', background: '#65BEFF', borderRadius: '50%' }}
            ></span>
            {hideMenu && (
              <Typography sx={{ ml: '4px', fontWeight: 500, fontSize: '16px', color: '#868E96' }}>
                {t('heldInTransactions')}:
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: hideMenu ? 'nowrap' : 'wrap',
              justifyContent: hideMenu ? 'flex-start' : 'center',
              mt: hideMenu ? 0 : 1,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1' }}
            >
              {Number(user.banned_frozen_limit).toFixed(2)}{' '}
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1', ml: 1 }}
            >
              USDT
            </Typography>
          </Box>
          <ListSubheader>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                flexDirection: 'column',
                mt: 2,
                mb: 1,
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography level="body-xs" sx={{ textAlign: 'center' }}>
                {t('sidebar_frozen_switch')}
              </Typography>
              <Switch
                color={accessibility ? 'primary' : 'danger'}
                size="lg"
                variant={'solid'}
                checked={accessibility}
                onChange={handleChangeCheckbox}
                sx={(theme: Theme) => ({
                  '--Switch-thumbSize': '27px',
                  '--Switch-trackWidth': '53px',
                  '--Switch-trackHeight': '31px',
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                  [`& .${switchClasses.thumb}`]: {
                    transition: 'width 0.2s, left 0.2s',
                  },
                  '&:hover': {
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                  },
                  '&:active': {
                    '--Switch-thumbWidth': '32px',
                  },
                  [`&.${switchClasses.checked}`]: {
                    '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                    '&:hover': {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                    },
                  },
                })}
              />
            </Box>
          </ListSubheader>
          <ListItem>
            <ListItemButton
              onClick={() => closeSidebar()}
              selected={pathname === '/dashboard-operator'}
              onMouseOver={() => setShowTitle(true)}
              onMouseOut={() => setShowTitle(false)}
              sx={{
                padding: '12px 0',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{
                  display: 'flex',
                  color: '#CED4DA',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                <NavLink
                  className={activeClassName}
                  to="dashboard-operator"
                  style={{
                    marginLeft: 1,
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: hideMenu ? 'flex-start' : 'center',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M18.3 10c-.4 0-.8-.2-1.2-.5-.3-.3-.4-.7-.4-1.2V1.7c0-.5.1-.9.4-1.2.4-.3.8-.5 1.2-.5h10c.5 0 .9.2 1.2.5.3.3.5.7.5 1.2v6.6c0 .5-.2.9-.5 1.2-.3.3-.7.5-1.2.5h-10ZM1.7 16.7c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2V1.7C0 1.2.2.8.5.5.8.2 1.2 0 1.7 0h10c.4 0 .8.2 1.2.5.3.3.4.7.4 1.2V15c0 .5-.1.9-.4 1.2-.4.3-.8.5-1.2.5h-10ZM18.3 30c-.4 0-.8-.2-1.2-.5-.3-.3-.4-.7-.4-1.2V15c0-.5.1-.9.4-1.2.4-.3.8-.5 1.2-.5h10c.5 0 .9.2 1.2.5.3.3.5.7.5 1.2v13.3c0 .5-.2.9-.5 1.2-.3.3-.7.5-1.2.5h-10ZM1.7 30c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2v-6.6c0-.5.2-.9.5-1.2.3-.3.7-.5 1.2-.5h10c.4 0 .8.2 1.2.5.3.3.4.7.4 1.2v6.6c0 .5-.1.9-.4 1.2-.4.3-.8.5-1.2.5h-10Z"
                    />
                  </svg>
                  {hideMenu && <Typography sx={{ ml: 1 }}>{t('sidebar_nav_dashboard')}</Typography>}
                  {!hideMenu && showTitle && (
                    <Box sx={boxStyle}>
                      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                        {t('sidebar_nav_dashboard')}
                      </Typography>
                      <Box sx={triangleStyle}></Box>
                    </Box>
                  )}
                </NavLink>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => closeSidebar()}
              selected={pathname === '/settings'}
              onMouseOver={() => setShow(true)}
              onMouseOut={() => setShow(false)}
              sx={{
                padding: '12px 0',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{
                  display: 'flex',
                  color: '#CED4DA',
                  fontSize: '18px',
                  fontWeight: 600,
                  position: 'relative',
                }}
              >
                <NavLink
                  className={activeClassName}
                  to="settings"
                  style={{
                    marginLeft: 1,
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: hideMenu ? 'flex-start' : 'center',
                    zIndex:30,
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M15 16.7c1.6 0 3-.6 4.1-1.7a5.6 5.6 0 0 0 1.7-4.2c0-1.6-.5-3-1.7-4A5.6 5.6 0 0 0 15 5c-1.6 0-3 .6-4.1 1.7a5.6 5.6 0 0 0-1.7 4.1c0 1.6.5 3 1.7 4.2a5.6 5.6 0 0 0 4.1 1.7ZM3.3 30c-.9 0-1.7-.3-2.3-1-.7-.6-1-1.4-1-2.3V3.3C0 2.4.3 1.6 1 1c.6-.7 1.4-1 2.3-1h23.4c.9 0 1.7.3 2.3 1 .7.6 1 1.4 1 2.3v23.4c0 .9-.3 1.7-1 2.3-.6.7-1.4 1-2.3 1H3.3Zm0-3.3h23.4v-2a16.8 16.8 0 0 0-18.1-3.4c-2 .8-3.8 2-5.3 3.4v2Z"
                    />
                  </svg>
                  {hideMenu && <Typography sx={{ ml: 1 }}>{t('sidebarAccaunt')}</Typography>}

                  {!hideMenu && show && (
                    <Box sx={boxStyle}>
                      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                        {t('sidebarAccaunt')}
                      </Typography>
                      <Box sx={triangleStyle}></Box>
                    </Box>
                  )}
                </NavLink>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => closeSidebar()}
              selected={pathname === '/active-transactions'}
              sx={{
                padding: '12px 0',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{
                  display: 'flex',
                  color: '#CED4DA',
                  fontSize: '16px',
                  fontWeight: 600,
                  justifyContent: hideMenu ? 'flex-start' : 'center',
                  position: 'relative',
                }}
              >
                <NavLink
                  className={activeClassName}
                  to="active-transactions"
                  style={{
                    marginLeft: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#CED4DA' }}
                  >
                    {t('sidebar_nav_active')}{' '}
                    {activeTransactions?.length > 0 && (
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: 'var(--error-color)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          position: !hideMenu ? 'absolute' : 'static',
                          top: 0,
                          right: 0
                        }}
                      >
                        {activeTransactions.length}
                      </Box>
                    )}
                  </Box>
                </NavLink>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <Divider />
          {hideMenu && (
            <ListSubheader sx={{ fontWeight: 500, color: '#868E96', fontSize: '16px', mt: 2 }}>
              {t('sidebarNavPayment')}
            </ListSubheader>
          )}

          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleButtonClick}
              onMouseOver={() => setShowPayIn(true)}
              onMouseOut={() => setShowPayIn(false)}
              sx={{
                padding: '12px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M20.3.3a13.3 13.3 0 0 1 5.5 25.5A13.3 13.3 0 1 1 8.2 8.2 13.3 13.3 0 0 1 20.3.3Zm-5 11.7H12v1.7a4.2 4.2 0 0 0-.3 8.3h3.8a.8.8 0 0 1 0 1.7H8.7V27H12v1.7h3.3V27a4.2 4.2 0 0 0 .3-8.3H12a.8.8 0 0 1 0-1.7h6.8v-3.3h-3.4V12Zm5-8.3A10 10 0 0 0 13 7a13.3 13.3 0 0 1 14 14.1 10 10 0 0 0-6.6-17.4Z"
                  />
                </svg>
                {hideMenu && (
                  <>
                    <Typography sx={{ color: '#CED4DA', fontSize: '18px', fontWeight: 600, ml: 1 }}>
                      {t('sideBarPayInP2P')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayin ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayIn && (
                  <Box sx={boxStyleM}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                      {t('sideBarPayInP2P')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}
              </ListItemContent>
            </ListItemButton>
            {showPayin && (
              <Box sx={{ display: 'block' }}>
                <ListItemButton onClick={() => closeSidebar()}
                  selected={pathname === '/cards'}
                >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="cards"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: '16px' }}
                      >
                        {t('sidebar_nav_cards')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
                <ListItemButton onClick={() => closeSidebar()}
                  selected={pathname === '/history-transactions'}>
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="history-transactions"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: '16px' }}
                      >
                        {t('sidebar_nav_history')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
              </Box>
            )}
          </ListItem>
          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={openPayinQAC}
              onMouseOver={() => setShowPayInQAC(true)}
              onMouseOut={() => setShowPayInQAC(false)}
              sx={{
                padding: '12px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M15 13.3a26 26 0 0 0 10.6-2c3-1.2 4.4-2.8 4.4-4.6 0-1.9-1.5-3.4-4.4-4.7A26 26 0 0 0 15 0 26 26 0 0 0 4.4 2C1.4 3.3 0 4.8 0 6.7c0 1.8 1.5 3.4 4.4 4.7 2.9 1.3 6.4 2 10.6 2Zm0 4.2a32.2 32.2 0 0 0 9.2-1.5c1.6-.5 3-1.2 4-2 1.2-1 1.8-2 1.8-3.2V15c0 1.2-.6 2.3-1.7 3.1-1.1.9-2.5 1.5-4 2a27.5 27.5 0 0 1-9.3 1.6 32.1 32.1 0 0 1-9.2-1.5c-1.6-.6-3-1.2-4-2-1.2-1-1.8-2-1.8-3.2v-4.2c0 1.3.6 2.3 1.7 3.1 1.1.9 2.5 1.6 4 2.1a27.5 27.5 0 0 0 9.3 1.5Zm0 8.3a32.2 32.2 0 0 0 9.2-1.5c1.6-.5 3-1.2 4-2 1.2-.9 1.8-2 1.8-3.1v4.1c0 1.3-.6 2.3-1.7 3.1-1.1.9-2.5 1.6-4 2.1A27.5 27.5 0 0 1 15 30a32.1 32.1 0 0 1-9.2-1.5c-1.6-.5-3-1.2-4-2-1.2-1-1.8-2-1.8-3.2v-4.1c0 1.2.6 2.2 1.7 3 1.1 1 2.5 1.6 4 2.1a27.5 27.5 0 0 0 9.3 1.5Z"
                  />
                </svg>
                {hideMenu && (
                  <>
                    <Typography
                      variant="plain"
                      sx={{ color: '#CED4DA', fontSize: '18px', fontWeight: 600, ml: 1 }}
                    >
                      {t('sideBarPayInQAC')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayinQAC ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayInQAC && (
                  <Box sx={boxStyleM}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                      {t('sideBarPayInQAC')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}
              </ListItemContent>
            </ListItemButton>
            {showPayinQAC && (
              <Box sx={{ display: 'block' }}>
                <ListItemButton onClick={() => closeSidebar()}
                  selected={pathname === '/terminals'}
                >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="terminals"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: '16px' }}
                      >
                      {t('historyTableTerminal')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
                <ListItemButton onClick={() => closeSidebar()}
                  selected={pathname === '/transaction-history-qac'}
                >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="transaction-history-qac"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: '16px' }}
                      >
                        {t('sidebarTRHistoryQAC')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
              </Box>
            )}
          </ListItem>
          {user.is_payout_permitted ? (
          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={openPayOut}
              onMouseOver={() => setShowPayOutTitle(true)}
              onMouseOut={() => setShowPayOutTitle(false)}
              sx={{
                padding: '12px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent
                sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M3.3 30c-.9 0-1.7-.3-2.3-1-.7-.6-1-1.4-1-2.3V3.3C0 2.4.3 1.6 1 1c.6-.7 1.4-1 2.3-1h23.4c.9 0 1.7.3 2.3 1 .7.6 1 1.4 1 2.3H16.7c-2 0-3.6.7-4.8 1.9A6.5 6.5 0 0 0 10 10v10c0 2 .6 3.6 1.9 4.8a6.5 6.5 0 0 0 4.8 1.9H30c0 .9-.3 1.7-1 2.3-.6.7-1.4 1-2.3 1H3.3Zm13.4-6.7c-1 0-1.7-.3-2.4-1-.6-.6-1-1.4-1-2.3V10c0-1 .4-1.7 1-2.4.7-.6 1.5-1 2.4-1h11.6c1 0 1.7.4 2.4 1 .6.7 1 1.5 1 2.4v10c0 1-.4 1.7-1 2.4-.7.6-1.4 1-2.4 1H16.7Zm5-5.8a2.4 2.4 0 0 0 2.5-2.5 2.4 2.4 0 0 0-2.5-2.5 2.4 2.4 0 0 0-2.5 2.5 2.4 2.4 0 0 0 2.5 2.5Z"
                  />
                </svg>
                {hideMenu && (
                  <>
                    <Typography
                      variant="plain"
                      sx={{ color: '#CED4DA', fontSize: '18px', fontWeight: 600, ml: 1 }}
                    >
                      {t('sideBarPayOut')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayOut ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayOutTitle && (
                  <Box sx={boxStyleM}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                      {t('sideBarPayOut')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}
              </ListItemContent>
            </ListItemButton>
            {showPayOut && (
              <Box sx={{ display: 'block' }}>
                <ListSubheader>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'column',
                      mt: 2,
                      mb: 1,
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography level="body-xs" sx={{ textAlign: 'center' }}>
                      {t('sidebar_payout_switch')}
                    </Typography>
                    <Switch
                      color={payoutAccessibility ? 'primary' : 'danger'}
                      size="lg"
                      variant={'solid'}
                      checked={payoutAccessibility}
                      onChange={handlePayoutChangeCheckbox}
                      sx={(theme: Theme) => ({
                        '--Switch-thumbSize': '27px',
                        '--Switch-trackWidth': '53px',
                        '--Switch-trackHeight': '31px',
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                        [`& .${switchClasses.thumb}`]: {
                          transition: 'width 0.2s, left 0.2s',
                        },
                        '&:hover': {
                          '--Switch-trackBackground': theme.vars.palette.background.level3,
                        },
                        '&:active': {
                          '--Switch-thumbWidth': '32px',
                        },
                        [`&.${switchClasses.checked}`]: {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                          '&:hover': {
                            '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                          },
                        },
                      })}
                    />
                  </Box>
                </ListSubheader>
                {user.is_payout_permitted ? (
                  <ListItem>
                    <ListItemButton
                      onClick={() => closeSidebar()}
                      selected={pathname === '/active-payouts'}
                      sx={{
                        padding: '12px 0',
                        '&.Mui-selected': {
                          backgroundColor: 'transparent',
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: '#343A40',
                        },
                        ':hover': {
                          backgroundColor: '#343A40',
                        },
                      }}
                    >
                      <ListItemContent
                        sx={{
                          display: 'flex',
                          color: '#CED4DA',
                          fontSize: '16px',
                          fontWeight: 600,
                          justifyContent: hideMenu ? 'flex-start' : 'center',
                          position: 'relative',
                        }}
                      >
                        <NavLink
                          className={activeClassName}
                          to="active-payouts"
                          style={{
                            marginLeft: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#CED4DA' }}
                          >
                            {t('sidebar_nav_payout_active')}{' '}
                            {activePayouts?.length > 0 && (
                              <Box
                                sx={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '50%',
                                  bgcolor: 'var(--error-color)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: '#fff',
                                  position: !hideMenu ? 'absolute' : 'static',
                                  top: 0,
                                  right: 0
                                }}
                              >
                                {activePayouts.length}
                              </Box>
                            )}
                          </Box>
                        </NavLink>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ): null }
                <ListItemButton onClick={() => closeSidebar()}
                                selected={pathname === '/history-payout'}>
                  <ListItemContent>
                    <NavLink
                      className={activeClassName}
                      to="history-payout"
                      style={{ color: '#CED4DA', fontWeight: 500, fontSize: '16px' }}
                    >
                      {t('sidebar_nav_payout_history')}
                    </NavLink>
                  </ListItemContent>
                </ListItemButton>
              </Box>
            )}
          </ListItem>
            ) : null}
          <ListItem sx={{ marginTop: '64px' }}>
            <ListItemButton onClick={handleSignOut}>
              <ListItemContent
                sx={{ display: 'flex', justifyContent: 'center', alignContent: 'baseline' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none">
                  <path
                    fill="#F1F1F1"
                    d="m3.8 7.4 3 3 .2.8c0 .2 0 .5-.3.7a1 1 0 0 1-.7.3c-.2 0-.5 0-.7-.3L.7 7.1a1 1 0 0 1-.2-.3V6l.2-.4L5.3 1c.2-.2.4-.3.7-.3.3 0 .5 0 .7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7l-2.9 3H13c.6 0 1 .2 1.4.6.4.5.6 1 .6 1.5v9.4c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3 1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7V7.4H3.8Z"
                  />
                </svg>
                {hideMenu &&(<Typography sx={{ color: '#F1F1F1', fontWeight: 600, ml: 1 }}>
                  {t('sidebar_exit')}
                </Typography>)}          
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </Sheet>
      <ModalTopUp
        openModal={openModal}
        toggleOpenModal={toggleOpenModal}
        onSubmit={onSubmit}
        amount={amount}
        handleChange={handleChange}
        disabled={disabled}
        handleRemoveFrozenBalance={handleRemoveFrozenBalance}
        isValidTopUp={isValidTopUp}
        isInvalidTopUp={isInvalidTopUp}
        minAmounTopup={minAmounTopup}
      />
      <ModalMyWallet
        isOpen={myWalletModal}
        onClose={() => setMyWalletModal(!myWalletModal)}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" fill="none">
            <path
              stroke="#18E806"
              strokeLinecap="round"
              strokeWidth="4"
              d="m2.7 28.7 10.7 8a3.3 3.3 0 0 0 4.6-.5L46 2"
            />
          </svg>
        }
        content={t('requestSuccess')}
      />
    </React.Fragment>
  );
};
export default Sidebar;
