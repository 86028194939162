import { Box, Typography, Switch, Theme, switchClasses, Button } from '@mui/joy';
import React, { useState, useEffect } from 'react';
import { Terminal, Banks } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import EditJarModal from './EditJarModal';
import DeleteCardModal from '../../components/CardList/DeleteCardModal';

const CardItemQAC = ({
  card,
  banks,
  handleDelete,
  handleUpdate,
  handleEditCard,
}: {
  card: Terminal;
  banks: Banks[];
  handleUpdate: ({ id, accessibility }: { id: number; accessibility: boolean }) => void;
  handleDelete: (id: number) => void;
  handleEditCard: (
    jars: {
      id: number;
      label: string;
      dailyLimit: number;
      monthly_limit: number;
      is_active: boolean;
      bank_id: number;
      daily_transaction_limit: number;
    }[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [accessibility, setAccessibility] = useState(card.is_active);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    setAccessibility(card.is_active);
  }, [card]);

  const handleChangeCheckbox = async () => {
    handleUpdate({
      id: card.id,
      accessibility: !accessibility,
    });
    setAccessibility(!accessibility);
  };
  const handleDeleteCard = async () => {
    handleDelete(card.id);
    setDeleteOpen(!deleteOpen);
  };
  return (
    <>
      <tr
        style={{
          backgroundColor: card.archivated ? 'var(--joy-palette-background-level1)' : 'transparent',
        }}
      >
        <td>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box>
              <Typography
                level="body-sm"
                sx={{ color: '#F1F1F1', overflowWrap: 'anywhere' }}
              >
                {card.jar_link}
              </Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                {card.label}
              </Typography>
            </Box>
          </Box>
        </td>
        <td>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="body-sm" sx={{ color: '#CED4DA'}}>
              {t('card_table_turnoverDay', {
                daily: (card.daily_balance && card.daily_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || '',
              })}
            </Typography>

            <Typography level="body-sm" sx={{ color: '#CED4DA',}}>
              {t('card_table_turnoverMonth', {
                monthly: (card.month_balance && card.month_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || 0,
              })}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1'}}>
            {`${card.daily_limit.toFixed(2) || 0} ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {`${card.month_limit.toFixed(2) || 0}  ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {card.daily_transaction_done} / {card.daily_transaction_limit}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {card.count_daily_success} / {card.count_daily_failed}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {card.count_total_success} / {card.count_total_failed}
          </Typography>
        </td>
        <td style={{ color: '#F1F1F1', fontSize: '16px' }}>{card.bank.bank.title}</td>
        <td>
          {!card.archivated && (
            <Switch
              size="md"
              variant={'solid'}
              checked={accessibility}
              onChange={handleChangeCheckbox}
              sx={(theme: Theme) => ({
                '--Switch-thumbSize': '20px',
                '--Switch-trackWidth': '53px',
                '--Switch-trackHeight': '27px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '25px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  '&:hover': {
                    '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  },
                },
              })}
            />
          )}
        </td>
        <td onMouseOver={() => setShowButtons(true)} onMouseOut={() => setShowButtons(false)}>
          {!showButtons && (
            <Button
              variant="plain"
              sx={{
                display: 'block',
                m: 0,
                borderBottom: '1px solid #947EFE',
                color: '#947EFE',
                borderRadius: 0,
                p: 0,
              }}
            >
              {t('cardChangeBtn')}
            </Button>
          )}

          {showButtons && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setEditOpen(!editOpen)}
              >
                {t('cardEditBtn')}
              </Button>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  ml: 1,
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setDeleteOpen(!deleteOpen)}
              >
                {t('cardDeleteBtn')}
              </Button>
            </Box>
          )}
        </td>
      </tr>
      <EditJarModal
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        card={card}
        handleEditCard={handleEditCard}
        banks={banks}
      />
      <DeleteCardModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDeleteCard={handleDeleteCard}
        textBtn={t('terminalmodalDeleteBtn')}
        title={t('terminalModalDeleteHeader')}
      />
    </>
  );
};
export default CardItemQAC;
