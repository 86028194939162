import {
  Box,
  Button,
  Sheet,
  Modal,
  Switch,
  Table,
  Input,
  Typography,
  Select,
  Option,
  FormControl,
  IconButton,
  switchClasses,
} from '@mui/joy';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useWidth from '../../hooks/useWidth';
import { getGlobalUser } from '../../redux/auth/selectors';
import { phonePattern } from '../../utils/patterns';
import Error from '../Error';
import { Cards } from '../../utils/types';
import { images, useCreditCardValidator } from 'react-creditcard-validator';
import CardItem from './CardItem';
import css from './CardList.module.css';
import { useCardList } from '../../hooks';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
// import { getPageSizes } from '../../utils/pagination';
import { useTranslation } from 'react-i18next';
import ModalAddTag from './ModalAddTag';
import { Tags } from '../../utils/types';
import { useState } from 'react';
import { getAllTags, getConstants } from '../../services/operators';
import ArchivedCardItem from './ArchivedCardItem';
import IconComponent from '../IconComponent/IconComponent';
import { Theme } from '@mui/joy';

import { selectClasses } from '@mui/joy/Select';

interface IFormInput {
  bank: string;
  tag: string;
  limit: number;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  send_id: string;
  iban: string;
  daily_transaction_limit: number;
  iban_only: boolean;
}

const CardList = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const [selected, setSelected] = useState<readonly number[]>([]);
  const [searchBank, setSearchBank] = useState<string>('');
  const [searchTag, setSearchTag] = useState<string>('');
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const { isMobile, isLargeDesktop } = useWidth();
  const { user } = useSelector(getGlobalUser);
  const [tagList, setTagList] = useState<Tags[]>([]);
  const [pageSizeConst, setPageSizeConst] = useState<number | null>(null);
  const [limitByMonth, setLimitByMonth] = useState('');
  const [limitByDay, setLimitByDay] = useState('');
  const [dailyTransactionLimit, setDailyTransactionLimit] = useState('');
  const {
    count,
    cards,
    banks,
    // tags,
    accessibilityAll,
    handleChangeCheckbox,
    expDateValidate,
    handleAddCart,
    handleDeleteCard,
    handleUpdateCard,
    handleRestSearch,
    handlePageClick,
    handleEditCard,
    archCards,
    countArchCards,
    handleArchPageClick
  } = useCardList({
    userId: user.id,
    searchQuery,
    searchBank,
    setSearchQuery,
    setSearchBank,
    setSearchTag,
    searchTag,
    pageSize: pageSizeConst,
  });

  const pageCount = Math.ceil(count /( pageSizeConst || 10));
  const pageArchCount = Math.ceil(countArchCards / (pageSizeConst || 10));
  const selectedCard = cards.filter(card => card?.archivated === false);

  const {
    getCardNumberProps,
    getCardImageProps,

    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<IFormInput> = ({
    accessibility,
    iban_only,
    bank,
    tag,
    limit_by_month,
    limit_by_the_day,
    label,
    send_id,
    iban,
    daily_transaction_limit,
  }: {
    accessibility: boolean;
    iban_only: boolean;
    bank: string | number;
    tag: number | null | string;
    limit_by_month: number;
    limit_by_the_day: number;
    label: string;
    send_id: string;
    iban: string;
    daily_transaction_limit:number
  }) => {
    const { ref } = getCardNumberProps();

    if (ref?.current?.value) {
      handleAddCart({
        cardNumber: ref?.current?.value,
        bank: parseFloat(bank as string),
        tag: parseFloat(tag as string),
        accessibility: (accessibility && accessibility) || false,
        iban_only: (iban_only && iban_only) || false,
        label,
        limit_by_the_day,
        limit_by_month,
        send_id,
        iban,
        daily_transaction_limit,
      });
      setModalOpen(!modalOpen);
      reset();
    } else {
      toast.error(erroredInputs.cardNumber);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const allTags = await getAllTags();
      setTagList(allTags);
    };
    getData();
  }, [modalOpen]);
  useEffect(() => {
    const getData = async () => {
      try {
        const constants = await getConstants();
        setPageSizeConst(Number(constants.DEFAULT_PAGINATION));
        setLimitByMonth(constants.MONTH_LIMITS_FOR_CARDS);
        setLimitByDay(constants.DAY_LIMITS_FOR_CARDS);
        setDailyTransactionLimit(constants.TRANSACTION_DAILY_LIMIT);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const handleChangeBank = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setSearchBank(value);
  };

  const handleChangeTag = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setSearchTag(value);
  };

  return (
    <>
      <Box
        sx={{
          display: (isLargeDesktop && 'flex') || 'block',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mt: 3,
          mb: (isLargeDesktop && 3) || 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1,
          }}
        >
          <Select
            placeholder={t('card_search_bank')}
            variant="outlined"
            slotProps={{
              listbox: {
                sx: {
                  '--List-padding': '0px',
                  '--ListItem-radius': '0px',
                },
              },
            }}
            indicator={
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path
                  fill="#ADB5BD"
                  d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                />
              </svg>
            }
            onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
              handleChangeBank(_event, newValue);
            }}
            value={searchBank}
            sx={{
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
              color: '#CED4DA',
              width: '170px',
              '--Input-radius': '8px',
              '--Select-placeholderOpacity': 1,
            }}
          >
            <Option
              key={'all'}
              value={'all'}
              sx={{
                  p: 0,
              }}
            >
              <Typography sx={{
                width: '100%', height: '100%', p: 1,
                color: '#F1F1F1',
                '&:hover, &:focus': {
                  backgroundColor: '#684FDD',
                },
              }}>
               {t('filter_All')}
              </Typography>
            </Option>
            {banks &&
              banks.map(({ title, id }) => (
                <Option
                  key={id}
                  value={id}
                  sx={{
                    p: 0,
                    '&[aria-selected="true"]': {
                      backgroundColor: '#684FDD',
                      color: '#F1F1F1',
                    },
                  }}
                >
                  <Typography sx={{
                    width: '100%', height: '100%', p: 1,
                    color: '#F1F1F1',
                    '&:hover, &:focus': {
                      backgroundColor: '#684FDD',
                    },
                  }}>
                    {title}
                  </Typography>
                </Option>
              ))}
          </Select>
          <Select
            placeholder={t('card_search_tag')}
            variant="outlined"
            slotProps={{
              listbox: {
                sx: {
                  '--List-padding': '0px',
                  '--ListItem-radius': '0px',
                },
              },
            }}
            indicator={
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path
                  fill="#ADB5BD"
                  d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                />
              </svg>
            }
            onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
              handleChangeTag(_event, newValue);
            }}
            value={searchTag}
            sx={{
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
              color: '#CED4DA',
              width: '170px',
              '--Input-radius': '8px',
              '--Select-placeholderOpacity': 1,
              ml: (isLargeDesktop && 1) || 0,
            }}
          >
            <Option
              key={'all'}
              value={'all'}
              sx={{
                p: 0,
              
              }}
            >
              <Typography sx={{
                width: '100%', height: '100%', p: 1,
                '&:hover, &:focus': {
                  backgroundColor: '#684FDD',
                  color: '#F1F1F1',
                },
              }}>
                {t('filter_All')}
              </Typography>
            </Option>
            {tagList &&
              tagList.map(({ title, id }) => (
                <Option
                  key={id}
                  value={id}
                  sx={{
                    p: 0,
                    '&[aria-selected="true"]': {
                      backgroundColor: '#684FDD',
                      color: '#F1F1F1',
                    },
                  }}
                >
                  <Typography sx={{
                    width: '100%', height: '100%', p: 1,
                    '&:hover, &:focus': {
                      backgroundColor: '#684FDD',
                      color: '#F1F1F1',
                    },
                  }}>
                    {title}
                  </Typography>
                </Option>
              ))}
          </Select>
          <Box sx={{ ml: 2, display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography level="body-md">{t('card_search_accessibility_all')}:</Typography>
            <Switch
              slotProps={{
                track: {
                  children: (
                    <React.Fragment>
                      <Typography
                        component="span"
                        level="inherit"
                        sx={{ ml: '6px', fontWeight: 500,color: '#F1F1F1' }}
                      >
                        On
                      </Typography>
                      <Typography
                        component="span"
                        level="inherit"
                        sx={{ mr: '6px', fontWeight: 500, }}
                      >
                        Off
                      </Typography>
                    </React.Fragment>
                  ),
                },
                thumb: {
                  children: (
                    <React.Fragment>
                      {!accessibilityAll ? (
                        <Typography
                          component="p"
                          level="inherit"
                          sx={{ fontWeight: 500, fontSize: '16px' }}
                        >
                          On
                        </Typography>
                      ) : (
                        <Typography
                          component="p"
                          level="inherit"
                          sx={{ fontWeight: 500, fontSize: '16px' }}
                        >
                          Off
                        </Typography>
                      )}
                    </React.Fragment>
                  ),
                },
              }}
              size="md"
              variant={'outlined'}
              checked={accessibilityAll}
              onChange={() => handleChangeCheckbox(selected)}
              sx={(theme: Theme) => ({
                '--Switch-thumbSize': '28px',
                '--Switch-trackWidth': '60px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackRadius': '28px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:active': {
                  '--Switch-thumbWidth': '28px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                },
              })}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            className={css.btnGradient}
            onClick={() => setIsAddTagModalOpen(true)}
            variant="outlined"
            sx={{
              color: '#F1F1F1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#F1F1F1"
                d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z"
              />
            </svg>
            {t('tag_addtag_btn')}
          </Button>
          <Button
            className={css.btnGradient}
            onClick={() => setModalOpen(!modalOpen)}
            variant="outlined"
            sx={{
              ml: 1,
              color: '#F1F1F1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#F1F1F1"
                d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z"
              />
            </svg>
            {t('card_add_btn')}
          </Button>
          <Button
            variant="outlined"
            className={css.btnGradient}
            onClick={handleRestSearch}
            sx={{
              ml: 1,
              color: '#f1f1f1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
          >
            {t('resetFilters')}
          </Button>
        </Box>
      </Box>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: 'center', width: '5%' }}>
              <label style={{ position: 'relative', display: 'inline-block' }}>
                <input
                  type="checkbox"
                  checked={selected.length === selectedCard?.length}
                  onChange={event => {
                    setSelected(
                      event.target.checked ? selectedCard.map((row: { id: number }) => row.id) : []
                    );
                  }}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    zIndex: 1,
                    opacity: 0,
                  }}
                />
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor:
                      selected.length === selectedCard?.length ? '#F1F1F1' : 'transparent',
                    appearance: 'none',
                    border: '1px solid #F1F1F1',
                    borderRadius: '6px',
                  }}
                >
                  {selected.length === selectedCard?.length && (
                    <span
                      style={{
                        position: 'absolute',
                        top: '42%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '26px',
                        color: '#495057',
                      }}
                    >
                      -
                    </span>
                  )}
                </div>
              </label>
            </th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_card')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_turnover')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_dayLimit')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_monthLimit')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('cardTableLimit') }</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_bank')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_modal_tag')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_accessibility')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          {cards.length > 0 &&
            cards
              .filter(card => !card.archivated)
              .map((card: Cards) => (
                <CardItem
                  banks={banks}
                  tagList={tagList}
                  card={card}
                  key={card.id}
                  handleDelete={handleDeleteCard}
                  handleUpdate={handleUpdateCard}
                  handleEditCard={handleEditCard}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
        </tbody>
      </Table>
      {cards.length === 0 && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
      {pageCount > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: (isMobile && '80%') || '40%',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight: '80vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
          </IconButton>
          <Typography
            level="h3"
            sx={{ textAlign: 'center', mb: '48px', color: '#CED4DA' }}
          >
            {t('card_modal_header')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ position: 'relative' }}>
              <svg
                {...getCardImageProps({ images })}
                style={{ position: 'absolute', top: '20px', right: 0, zIndex: 10, width: '60px' }}
              />
              <input
                className={`${css.input}`}
                {...getCardNumberProps()}
                name="cardNumber"
                id="cardNumber"
                autoFocus
                placeholder={t('card_modal_card')}
                maxLength={33}
              />
            </Box>
            <Input
              {...register('label')}
              name="label"
              id="label"
              placeholder={t('card_modal_lebel')}
              sx={{
                mt: 4,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="label" />
            <Input
              {...register('send_id')}
              name="send_id"
              id="send_id"
              placeholder={t('cardModalID')}
              sx={{
                mt: 4,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('send_id', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Input
              {...register('iban')}
              name="iban"
              id="iban"
              placeholder={t('cardModalIban')}
              sx={{
                mt: 4,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('iban', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="iban" />
   
              <Typography sx={{ mt: 2 }} level="body-md">
                {t('cardModalDailyLimit')}
            </Typography>
            <Input
              {...register('daily_transaction_limit')}
              name="daily_transaction_limit"
              id="daily_transaction_limit"
              defaultValue={dailyTransactionLimit}
              type="number"
              slotProps={{
                input: {
                  min: 1,
                },
              }}
              placeholder={t('cardModalDailyLimit')}
              sx={{
                mt: 2,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="daily_transaction_limit" />
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <FormControl sx={{ width: '100%' }}>
                <Typography sx={{ mb: 1 }} level="body-md">
                  {t('card_modal_monthLimit')}
                </Typography>
                <Input
                  {...register('limit_by_month', {
                    pattern: {
                      value: phonePattern,
                      message: t('validation_numbers'),
                    },
                    required: t('validation_required'),
                  })}
                  defaultValue={limitByMonth}
                  name="limit_by_month"
                  id="limit_by_month"
                  placeholder={t('card_modal_monthLimit')}
                  sx={{
                    '--Input-focusedThickness': '0',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    width: '100%',
                    borderBottom: errors.limit_by_month
                      ? '1px solid rgba(235, 87, 87, 1)'
                      : '1px solid rgba(24, 232, 6, 1)',
                  }}
                />
                <Error errors={errors} name="limit_by_month" />
              </FormControl>
              <FormControl sx={{ width: '100%', mt:isMobile ? 2 : 0 }}>
                <Typography sx={{ mb: 1 }} level="body-md">
                  {t('card_modal_dayLimit')}
                </Typography>
                <Input
                  {...register('limit_by_the_day', {
                    pattern: {
                      value: phonePattern,
                      message: t('validation_numbers'),
                    },
                    required: t('validation_required'),
                  })}
                  className={css.input}
                  name="limit_by_the_day"
                  defaultValue={limitByDay}
                  id="limit_by_the_day"
                  placeholder={t('card_modal_dayLimit')}
                  sx={{
                    '--Input-focusedThickness': '0',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    width: '100%',
                    borderBottom:
                      parseFloat(watch('limit_by_the_day')?.toString()) >
                        parseFloat(watch('limit_by_month')?.toString())
                        ? '1px solid rgba(235, 87, 87, 1)'
                        : '1px solid rgba(24, 232, 6, 1)',
                  }}
                />
                <Error errors={errors} name="limit_by_the_day" />
              </FormControl>
            </Box>
            <Controller
              control={control}
              rules={{
                required: t('validation_required'),
              }}
              name="bank"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_bank')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
               
                  indicator={
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                      <path
                        fill="#ADB5BD"
                        d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                      />
                    </svg>
                  }
                  sx={{
                    [`& .${selectClasses.indicator}`]: {
                      transition: '0.2s',
                      [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                      },
                    },
                    mt: 4,
                    height: '56px',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {banks &&
                    banks.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />

            <Error errors={errors} name="bank" />
            <Controller
              control={control}
              name="tag"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_tag')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
                  indicator={
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                      <path
                        fill="#ADB5BD"
                        d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                      />
                    </svg>
                  }
                  sx={{
                    [`& .${selectClasses.indicator}`]: {
                      transition: '0.2s',
                      [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                      },
                    },
                    mt: 4,
                    height: '56px',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {tagList &&
                    tagList.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />
            <Error errors={errors} name="tag" />
            <Box
              sx={{ display: 'flex', gap: '10px', alignItems: 'center', mt: '34px', mb: '40px' }}
            >
              <Typography level="body-lg" sx={{ color: '#F1F1F1' }}>
                {t('card_modal_accessibility')}
              </Typography>
              <Controller
                control={control}
                name="accessibility"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    size="md"
                    variant="solid"
                    sx={(theme: Theme) => ({
                      '--Switch-thumbSize': '20px',
                      '--Switch-trackWidth': '48px',
                      '--Switch-trackHeight': '26px',
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                      [`& .${switchClasses.thumb}`]: {
                        transition: 'width 0.2s, left 0.2s',
                      },
                      '&:hover': {
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                      },
                      '&:active': {
                        '--Switch-thumbWidth': '27px',
                      },
                      [`&.${switchClasses.checked}`]: {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        '&:hover': {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        },
                      },
                    })}
                    defaultValue={false}
                    value={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore

                    onChange={(
                      event: { target: { checked: boolean } },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      _newValue: string
                    ) => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ display: 'flex', gap: '10px', alignItems: 'center', mt: '34px', mb: '40px' }}
            >
              <Typography level="body-lg" sx={{ color: '#F1F1F1' }}>
                {t('card_modal_iban_only')}
              </Typography>
              <Controller
                control={control}
                name="iban_only"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    size="md"
                    variant="solid"
                    sx={(theme: Theme) => ({
                      '--Switch-thumbSize': '20px',
                      '--Switch-trackWidth': '48px',
                      '--Switch-trackHeight': '26px',
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                      [`& .${switchClasses.thumb}`]: {
                        transition: 'width 0.2s, left 0.2s',
                      },
                      '&:hover': {
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                      },
                      '&:active': {
                        '--Switch-thumbWidth': '27px',
                      },
                      [`&.${switchClasses.checked}`]: {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        '&:hover': {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        },
                      },
                    })}
                    defaultValue={false}
                    value={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore

                    onChange={(
                      event: { target: { checked: boolean } },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      _newValue: string
                    ) => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Button
                type="submit"
                variant="outlined"
                className="btnGradient"
                sx={{
                  width: '100%',
                  p: '17px 0',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                disabled={!isValid}
              >
                {t('card_add_btn')}
              </Button>
              <Button
                type="button"
                variant="outlined"
                className="btnGradient"
                sx={{
                  width: '100%',

                  p: '17px 0',
                  fontSize: '14px',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                    color: '#f1f1f1',
                  },
                }}
                onClick={() => {
                  setModalOpen(!modalOpen);
                }}
              >
                {t('withdrawalBtn')}
              </Button>
            </Box>
          </form>
        </Sheet>
      </Modal>
      {isAddTagModalOpen && (
        <ModalAddTag
          setIsAddTagModalOpen={setIsAddTagModalOpen}
          isAddTagModalOpen={isAddTagModalOpen}
        />
      )}
      {archCards?.length > 0 && (
        <>
          <Typography level="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
            {t('archivedCards')}
          </Typography>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
            }}
          >
            <thead>
              <tr>
                <th style={{ width: '16%', color: '#F1F1F1' }}>{t('card_table_card')}</th>
                <th style={{ width: '15%', color: '#F1F1F1' }}>{t('card_table_turnover')}</th>
                <th style={{ width: '10%', color: '#F1F1F1' }}>{t('card_table_dayLimit')}</th>
                <th style={{ width: '12%', color: '#F1F1F1' }}>{t('card_table_monthLimit')}</th>
                <th style={{ width: '12%', color: '#F1F1F1' }}>{t('card_table_bank')}</th>
                <th style={{ width: '15%', color: '#F1F1F1' }}>{t('card_modal_tag')}</th>
                <th style={{ width: '10%', color: '#F1F1F1' }}>{t('card_table_actions')}</th>
              </tr>
            </thead>
            <tbody>
              {archCards?.map(card => (
                <ArchivedCardItem handleDelete={handleDeleteCard} card={card} key={card.id} />
              ))}
            </tbody>
          </Table>
          {archCards.length === 0 && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
          {pageArchCount > 1 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <ReactPaginate
                onPageChange={handleArchPageClick}
                pageCount={pageArchCount}
                previousLabel={t('pagination_prev')}
                nextLabel={t('pagination_next')}
                containerClassName={'pagination'}
                pageLinkClassName={'page-number'}
                previousLinkClassName={'page-number'}
                nextLinkClassName={'page-number'}
                activeLinkClassName={'active'}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default CardList;
