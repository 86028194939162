import React from 'react';
import { Box, Typography } from '@mui/joy';
import { Banks } from '../../../utils/types';

const Stepper = ({
  activeStep,
  amount,
  currentBank,
  steps,
}: {
  activeStep: number;
  amount: string;
  currentBank: Banks | null;
  steps: string[];
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ml: '20px',
        height: '100%',
      }}
    >
      {steps.map((step, index) => (
        <Box key={index} sx={{ display: 'flex', mb: '20px' }}>
          <Box
            key={step}
            sx={{
              display: 'flex',
              flexDirection: 'column',

              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', gap: '6px' }}>
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  backgroundColor:
                    activeStep === index ? 'var(--accent-focus)' : 'var(--accent-light)',
                  color: activeStep === index ? 'white' : 'black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {index + 1}
              </Box>
              <Box>
                <Typography level="title-md">{step}</Typography>
                {index === 0 && amount !== '' && (
                  <Typography level="body-md">{`${amount} ${localStorage.getItem('base_currency')}`}</Typography>
                )}
                {index === 1 && currentBank && (
                  <Typography level="body-md">{currentBank.title}</Typography>
                )}
              </Box>
            </Box>
            {activeStep === index && (
              <Box
                sx={{
                  width: '2px',
                  height: '60vh',
                  backgroundColor: 'var(--accent-focus)',
                  mt: '-2px',
                  ml: '11px',
                  borderRadius: '1px',
                }}
              />
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Stepper;
