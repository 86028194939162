export const formatingCardNumber = (numb: string): string => {
    if (!numb) {
        return numb;
    }
    const digitsOnly = numb.replace(/\D/g, '');
    const firstSix = digitsOnly.slice(0, 6);
    const lastFourDigits = digitsOnly.slice(-4);
    const maskedNumber = firstSix + '***' + lastFourDigits;
    return maskedNumber;
};
