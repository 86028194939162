import { toast } from 'react-toastify';
import api from './axiosConfig';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { formatDateBE } from '../utils/datePickerFormat';
import { TransactionsAndBalance } from '../redux/operatorBalanceAndTransactions/types';

export const getAllCards = async (
  id: number,
  searchQuery: string,
  searchBank: string,
  page: number,
  pageSize: number | null,
  searchTag: string,
  archivated: boolean,
) => {
  try {
    const { data } = await api.get(`cards/cards/${id}`, {
      params: {
        ...(searchQuery && { labels: searchQuery }),
        ...(searchBank && { bank_id: searchBank }),
        ...(searchTag && { tag_id: searchTag }),
        page,
        pageSize,
        archivated
      },
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getAllTrans = async (
  id: number,
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    searchByPocket: string | null;
    searchByChangedBy: string | null;
    searchByType: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchBank, searchStatus, searchByPocket, searchByChangedBy, searchByType, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `transaction/operator/findAll/${id}?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchBank && { bank: searchBank }),
        ...(searchStatus && { status: searchStatus }),
        ...(searchByPocket && { pocket_address: searchByPocket }),
        ...(searchByChangedBy && { status_changed_by: searchByChangedBy }),
        ...(searchByType && { type: searchByType }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
          date: {
            from: `${formatDateBE(sDate as string)} 00:00`,
            to: `${formatDateBE(fDate as string)} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getAllPayouts = async (
  id: number,
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    searchByPocket: string | null;
    searchByChangedBy: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchBank, searchStatus, searchByPocket, searchByChangedBy, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `payout/operator/findAll/${id}?page=${(itemOffset && itemOffset) || ''}&page_size=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchBank && { bank: searchBank }),
        ...(searchStatus && { status: searchStatus }),
        ...(searchByPocket && { pocket_address: searchByPocket }),
        ...(searchByChangedBy && { status_changed_by: searchByChangedBy }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
            date: {
              from: `${formatDateBE(sDate as string)} 00:00`,
              to: `${formatDateBE(fDate as string)} 23:59`,
            },
          }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getTransactionById = async (id: number) => {
  try {
    const { data } = await api.get(`transaction/operator/${id}`);

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const savePayoutBanks = async (banks: any) => {
  try {
    const { data } = await api.post(`account/operator/payout-banks`, {
      payout_banks: banks,
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getMerchantCourse = async (id: number) => {
  try {
    const { data } = await api.get(`payout/operator/course/${id}`);

    return data.course;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getPayoutById = async (id: number) => {
  try {
    const { data } = await api.get(`payout/operator/${id}`);

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const changeTransactionStatus = async (id: number, status: string) => {
  try {
    const transactions = [{ transaction_id: id, status: status, confirmed_amount_uah: null }]
    const { data } = await api.patch(`transaction/operator/operatorStatusUpdate`,
      {
        transactions
      });
    toast.success(i18n.t('toast_changeStatus_success'), {
      position: 'top-right',
    });
    return data;
  } catch (err: any) {
    toast.error(i18n.t('toast_changeStatus_success'), {
      position: 'top-right',
    });
  }
};

export const changeAcqTransactionStatus = async (id: number, status: string) => {
  try {
    const transactions = [{ transaction_id: id, status: status, confirmed_amount_uah: null }]
    const { data } = await api.patch(`transaction-acquiring/operator/operatorStatus`,
      {
        transactions
      });
    toast.success(i18n.t('toast_changeStatus_success'), {
      position: 'top-right',
    });
    return data;
  } catch (err: any) {
    toast.error(i18n.t('toast_changeStatus_success'), {
      position: 'top-right',
    });
  }
};

export const getWithdrawalsHistory = async (itemOffset: number | null, pageSize: number | null) => {
  try {
    const { data } = await api.post(
      `account/operator/balance/withdrawAll/?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getStats = async (
  id: number,

  filterQueries: {
    sDate: null | Date | string;
    fDate: null | Date | string;
  }
) => {
  const { sDate, fDate } = filterQueries;
  const defaultDate = new Date();
  try {
    const { data } = await api.post(
      `transaction/operator/getStatistics/${id}
      `,
      {
        ...((sDate &&
          fDate && {
          date: {
            from: `${sDate} 00:00`,
            to: `${fDate} 23:59`,
          },
        }) || {
          date: {
            from: `${formatDateBE(defaultDate.toString())} 00:00`,
            to: `${formatDateBE(defaultDate.toString())} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};
export const updateTrans = async (notif_id: number, amount_uah:number) => {
  try {
    const { data } = await api.post(`transaction/operator/confirm/${notif_id}`, {amount_uah});

    return data;
  } catch (err: unknown) {
    toast.error(i18n.t('toast_updateTrans'), {
      position: 'top-right',
    });
  }
};

export const updatePayout = async (
  payoutId: number,
  amount: number,
  receiptNumber: string,
  receiptUrl: string,
  receiptAttachments: File[] | null
) => {
  try {
    const formData = new FormData();
    formData.append('amount', amount.toString());
    formData.append('receipt_number', receiptNumber);
    formData.append('receipt_url', receiptUrl);
    if (receiptAttachments !== null) {
      receiptAttachments.forEach((file) => {
        formData.append(`files`, file);
      });
    }

    const { data } = await api.post(`payout/operator/confirm/${payoutId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success(i18n.t('toast_AcceptPayoutSuccess'));
    return data;
  } catch (err: unknown) {
    toast.error(i18n.t('toast_updatePayout'), {
      position: 'top-right',
    });
  }
};

export const cancelPayout = async (payoutId: number, cancelReason: string) => {
  if (!cancelReason) {
    toast.error("Reason couldn't be empty.", {
      position: 'top-right',
    });
  }
  try {
    const { data } = await api.post(`payout/operator/cancel/${payoutId}`,
      {
        cancel_reason: cancelReason
      }
    );
    toast.success(i18n.t('toast_CancelPayoutSuccess'));
    return data;
  } catch (err: unknown) {
    toast.error(i18n.t('toast_cancelPayoutTrans'), {
      position: 'top-right',
    });
  }
};

export const processPayoutStatus = async (payoutId: number) => {
  try {
    const { data } = await api.post(`payout/operator/switch-status/${payoutId}`);
    return data;
  } catch (err: unknown) {
    toast.error(i18n.t('toast_updateTrans'), {
      position: 'top-right',
    });
  }
};

export const savePayoutCapacity = async (desiredCapacity: number) => {
  try {
    const { data } = await api.post(`account/operator/payout/set-capacity`,
      {
        desired_payout_capacity: desiredCapacity
      }
    );
    toast.success(i18n.t('toast_capacity_set'));
    return data;
  } catch (err: any) {
    toast.error(err.response.data.message, {
      position: 'top-right',
    });
  }
};

export const uploadImage = async (formData: FormData) => {
  try {
    const response = await api.post('payout/operator/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const addNewCard = async (
  cardNumber: string | number | readonly string[],
  bankId: number,
  isActive: boolean,
  label: string,
  daily_limit: number,
  month_limit: number,
  tag_id: number | null,
  send_id: string,
  iban: string,
  iban_only: boolean,
  daily_transaction_limit:number,
) => {
  try {
    const { data } = await api.post(`cards`, {
      card_number: cardNumber,
      bank_id: bankId,
      is_active: isActive,
      tag_id: tag_id,
      label,
      dailyLimit: Number(daily_limit),
      monthly_limit: Number(month_limit),
      send_id,
      iban,
      iban_only,
      daily_transaction_limit,
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 409) {
      toast.error(i18n.t('toast_addCard'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const updateCardAll = async (
  user_id: number,
  cards: { id: number; is_active: boolean }[]
) => {
  try {
    const { data } = await api.patch(`cards/updateCard`, {
      user_id,
      cards,
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const editCard = async (
  user_id: number,
  cards: {
    id: number;
    bank_id: number;
    tag: number | null;
    monthly_limit: number;
    dailyLimit: number;
    label: string;
    is_active: boolean;
    send_id: string;
    daily_transaction_limit: number;
  }[]
) => {
  try {
    const { data } = await api.patch(`cards/updateCard`, {
      user_id,
      cards,
    });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 400) {
      toast.error(i18n.t('toast_wrongLimit'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const deleteCard = async (id: number) => {
  try {
    const { data } = await api.delete(`cards/${id}`);

    return data;
  } catch {
    toast.error(i18n.t('toast_deleteCard'), {
      position: 'top-right',
    });
  }
};

export const withdrawalBalance = async (amount: number) => {
  try {
    const { data } = await api.post(`account/operator/balance/withdraw`, { amount });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 409) {
      toast.error(i18n.t('toast_withdrawal_validate'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const getAllBanks = async (api_key: string | null) => {
  try {
    const { data } = await api.get('banks', { headers: { 'X-Api-key': api_key } });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getAllTags = async () => {
  try {
    const { data } = await api.get('cards/getTags');

    return data;
  } catch {
    return;
  }
};

export const getCourse = async () => {
  try {
    const { data } = await api.get('course/fetchData');

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const addNewTag = async (title: string) => {
  try {
    const { data } = await api.post('cards/tagCreate', { title });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error);
  }
}
export const removeTag = async (id: number) => {
  try {
    const { data } = await api.delete(`cards/tag/delete/${id}`);
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error);
  }
}

export const updateTag = async (id: number, title: string) => {

  try {
    const { data } = await api.patch(`cards/tag/update/${id}`, { title });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error);
  }
}

export const getBalanceAndTransactions = async () => {
  try {
    const response = await api.get<TransactionsAndBalance>('account/operator/balance-and-transactions');
    return response;
  } catch (error) {

    toast.error(i18n.t('toastDepostError'));
    throw error; 
  }
};
export const generateApiKey = async () => {
  try {
    const { data } = await api.get(`account/operator/mobile/api/generate`);
    return data;
  } catch {
    toast.error(i18n.t('toast_generateApi_error'), {
      position: 'top-right',
    });
  }
};

export const updateMonoToken = async (token: string) => {
  try {
    const { data } = await api.post(`account/operator/mono/token/update`, {
      token: token
    });
    return data;
  } catch {
    toast.error(i18n.t('toast_monoToken_error'), {
      position: 'top-right',
    });
  }
};

export const updateCallbackUrl = async (url: string) => {
  try {
    const { data } = await api.post(`account/operator/callback/update`, {
      callback_url: url
    });
    return data;
  } catch {
    toast.error(i18n.t('toast_monoToken_error'), {
      position: 'top-right',
    });
  }
};

export const deleteMonoToken = async (token: string) => {
  try {
    const { data } = await api.post(`account/operator/mono/token/delete`, {
      token: token
    });
    return data;
  } catch {
    toast.error(i18n.t('toast_monoToken_deleted_success'), {
      position: 'top-right',
    });
  }
};

export const getBanks = async (token: string | null) => {
  try {
    const { data } = await api.get('banks/operator', { headers: { "Authorization": `Bearer ${token}` } });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};
export const getOperatorCourse = async (token: string | null, fiat: string) => {
  try {
    const { data } = await api.get(
      'course/fetchData/operator',
      {
        headers: { "Authorization": `Bearer ${token}` },
        params: { fiat }
      });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const cancelWithdrawalRequest = async (id: number) => {
  try {
    const { data } = await api.get(`/account/operator/balance/withdraw/cancel/${id}`);
    return data;
  } catch {
    toast.error(i18n.t('toastCancelWithdrawalRequestError'), {
      position: 'top-right',
    });
  }
};

export const getJar = async (
  id: number,
  searchBank: string,
  page: number,
  pageSize: number | null,
  archivated:boolean
) => {
  try {
    const { data } = await api.get(`jars/jars/${id}`, {
      params: {
        ...(searchBank && { bank_id: searchBank }),
        page,
        pageSize,
        archivated
      },
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
};


export const addCard = async (
  jar_link: string,
  bankId: number,
  isActive: boolean,
  dailyLimit: number,
  monthlyLimit: number,
  label: string,
  daily_transaction_limit: number,

) => {
  try {
    const { data } = await api.post(`jars`, {
      jar_link,
      bank_id: bankId,
      is_active: isActive,
      dailyLimit: Number(dailyLimit),
      monthly_limit: Number(monthlyLimit),
      label,
      daily_transaction_limit,
    });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 409) {
      toast.error(i18n.t('toastAddTeminalConflict'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const deleteJar = async (id: number) => {
  try {
    const { data } = await api.delete(`jars/${id}`);

    return data;
  } catch {
    toast.error(i18n.t('toastDeleteTerminal'), {
      position: 'top-right',
    });
  }
};

export const editJar = async (
  jars: {
    id: number;
    bank_id: number;
    monthly_limit: number;
    dailyLimit: number;
    label: string;
    is_active: boolean;
    daily_transaction_limit: number;
  }[]) => {
  try {
    const { data } = await api.patch(`jars/updateJar`, {
      jars
    });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    if (error.response?.status === 400) {
      toast.error(i18n.t('toastWrongLimitTerminal'), {
        position: 'top-right',
      });
    }
    console.log(error.message);
  }
};

export const updateJar = async (
  jars: { id: number; is_active: boolean }[]
) => {
  try {
    const { data } = await api.patch(`jars/updateJar`, {
      jars,
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};


export const getAllTransQAC = async (
  // id: number,
  itemOffset: number | null,
  pageSize: number | null,
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }
) => {
  const { searchQuery, searchBank, searchStatus, sDate, fDate, sort } = filterQueries;
  try {
    const { data } = await api.post(
      `/transaction-acquiring/operator/getAll/acq?page=${(itemOffset && itemOffset) || ''}&pageSize=${(pageSize && pageSize) || ''
      }`,
      {
        ...(searchQuery && { label: searchQuery }),
        ...(searchBank && { bank: searchBank }),
        ...(searchStatus && { status: searchStatus }),
        ...(sort && { sort }),
        ...(sDate &&
          fDate && {
          date: {
            from: `${formatDateBE(sDate as string)} 00:00`,
            to: `${formatDateBE(fDate as string)} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getQacTransactioById = async (id: number) => {
  try {
    const { data } = await api.get(`transaction-acquiring/operator/acq/${id}`);

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};


export const downloadHistory = async (filterQueries: {
  searchQuery: string;
  searchBank: string | null;
  searchStatus: string | null;
  sDate: null | Date | string;
  fDate: null | Date | string;
}, token: string | null) => {
  try {

    const filterData = {
      ...(filterQueries.searchQuery !== "" && { label: filterQueries.searchQuery }),
      ...(filterQueries.searchBank !== "" && { bank: filterQueries.searchBank }),
      ...(filterQueries.searchStatus !== null && { status: filterQueries.searchStatus }),
      ...(filterQueries.sDate !== null && { date: { from: filterQueries.sDate } }),
      ...(filterQueries.fDate !== null && { date: { from: filterQueries.sDate, to: filterQueries.fDate } }),
    };

    const response = await api.post('transaction/operator/getData/csv', filterData, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const blobData = response.data;
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transaction_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error', error);
  }
};

export const downloadHistoryQAC = async (filterQueries: {
  searchQuery: string;
  searchBank: string | null;
  searchStatus: string | null;
  sDate: null | Date | string;
  fDate: null | Date | string;
}, token: string | null) => {
  try {

    const filterData = {
      ...(filterQueries.searchQuery !== "" && { label: filterQueries.searchQuery }),
      ...(filterQueries.searchBank !== "" && { bank: filterQueries.searchBank }),
      ...(filterQueries.searchStatus !== null && { status: filterQueries.searchStatus }),
      ...(filterQueries.sDate !== null && { date: { from: filterQueries.sDate } }),
      ...(filterQueries.fDate !== null && { date: { from: filterQueries.sDate, to: filterQueries.fDate } }),
    };

    const response = await api.post('transaction-acquiring/operator/getData/csv', filterData, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const blobData = response.data;
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transaction_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error', error);
  }
};

export const findTransactionById = async (id: number | null, transaction_id: number | string) => {
  try {
    const { data } = await api.post(`transaction/operator/findAll/${id}`, { transaction_id});
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const findQACTransactionById = async (transaction_id: number | string) => {
  try {
    const { data } = await api.post(`transaction-acquiring/operator/getAll/acq/`, { transaction_id });
    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

export const getConstants = async () => {
  try {
    const { data } = await api.get('account/operator/settings/constants');
    return data;
  }
  catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
  }
}

export const getAllStats = async (
  filterQueries: {
    sDate: null | Date | string;
    fDate: null | Date | string;
  }
) => {
  const { sDate, fDate } = filterQueries;
  const defaultDate = new Date();
  try {
    const { data } = await api.post(
      'account/operator/getStatistics/all',
      {
        ...((sDate &&
          fDate && {
          date: {
            from: `${sDate} 00:00`,
            to: `${fDate} 23:59`,
          },
        }) || {
          date: {
            from: `${formatDateBE(defaultDate.toString())} 00:00`,
            to: `${formatDateBE(defaultDate.toString())} 23:59`,
          },
        }),
      }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;

    console.log(error.message);
  }
};

